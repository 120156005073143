
import { defineComponent, computed } from "vue";
import useNetwork from "../composables/useNetwork";
import { useStore } from "@/store/store";
import { useGtm } from '@gtm-support/vue-gtm';


export default defineComponent({
  name: "WalletConnectionHandler",

  setup: async () => {
    const store = useStore();
    const { connectToNetwork, disconnectFromNetwork } = await useNetwork(store);

    const gtm = useGtm();

    if (localStorage.getItem('address') != null) {
        store.commit("setIMXRegistered", true);
    } else {
        store.commit("setIMXRegistered", false);
    }

    const doConnectNetwork = async () => {
      await connectToNetwork();
      if (gtm != undefined) {
          gtm.trackEvent({
              event: 'Connect Network',
              category: 'connection',
              action: 'click',
              label: 'User clicks on Connect Network button',
              value: 5000,
              noninteraction: false,
            });
      }
    };

    

    return {
      selectedAccount: computed(() => store.state.currentUser),
      isIMXRegistered: computed(() => store.state.isIMXRegistered),
      doConnectNetwork,
      disconnectFromNetwork,
    };
  },

});
