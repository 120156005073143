
import { State } from "@/store/store";
import { AbiItem } from 'web3-utils';
import dogggoNFTAbi from "../contracts/DogggoReserveNFT.json";
import { DOGGGO_NFT_ADDRESS } from "../constants";

export default async function useNFTContract(state: State) {
    const getDogggoNFTContract = () => new state.web3.eth.Contract(dogggoNFTAbi as AbiItem[], DOGGGO_NFT_ADDRESS);

    const getNFTSold = async () => await getDogggoNFTContract().methods.nftSold().call();
    const getDogggoCollected = async () => await getDogggoNFTContract().methods.dogggoCollected().call();
    const getNFTAvailable = async () => await getDogggoNFTContract().methods.NFT_AVAILABLE().call();
    const getOpeningTime = async () => await getDogggoNFTContract().methods.openingTime().call();
    const getNFTReserved = async () => await getDogggoNFTContract().methods.nftReserved(state.currentUser).call();
    const getNFTToDogggoConversion = async (nftAmount: number) => await getDogggoNFTContract().methods.nftToDogggoConversion(nftAmount).call();
    const reserveNFT = async (nftAmount: string) => await getDogggoNFTContract().methods.reserveNft(nftAmount).send({ from: state.currentUser })

    return {
        getNFTSold,
        getDogggoCollected,
        getNFTAvailable,
        getOpeningTime,
        getNFTReserved,
        getNFTToDogggoConversion,
        reserveNFT
    }
}