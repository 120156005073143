import { Link } from '@imtbl/imx-sdk';
import { IMMUTABLE_X_LINK_URL } from "../constants";

export default async function useImmutableX() {
    const registerImmutableX = async () => {
        const link = new Link(IMMUTABLE_X_LINK_URL);
        const { address } = await link.setup({});
        localStorage.setItem('address', address);
        location.reload();
    };

    return {
        registerImmutableX
    }
}

