
import { BINANCE_CONNECTION } from "@/constants";
import { State } from "@/store/store";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Store } from "vuex";
import Web3Modal from "web3modal";

const providerOptions = {
    walletconnect: {
        display: {
            description: "wooof wooof connect!"
        },
        package: WalletConnectProvider,
        options: {
            ...BINANCE_CONNECTION,
            qrcode: true,
            pollingInterval: 3000,
        },
    },
};

const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
});

export default async function useNetwork(store: Store<State>) {
    const connectToNetwork = async () => {
        let provider;
        try {
            provider = await web3Modal.connect();
        } catch (e) {
            console.log("Could not get a wallet connection", e);
            return;
        }

        store.commit("setupWeb3", provider);

        provider.on("accountsChanged", async () => {
            if (store.state.currentUser != '') {
                location.reload();
            }
        });

        provider.on("networkChanged", async () => {
            if (store.state.currentUser != '') {
                location.reload();
            }
        });


        provider.on("connect", async () => {
            if (store.state.currentUser != '') {
                await fetchAccountData();
            }
        });

        await fetchAccountData();
    }

    const unwatchChanges = () => {
        store.state.watcher = false;
    }

    const disconnectFromNetwork = async () => {
        unwatchChanges();
        web3Modal.clearCachedProvider();
        store.commit("setCurrentUser", "");
        store.commit("setChainId", 0);
        store.commit("setupWeb3", null);
        store.commit("setCurrentUserNFT", []);
        store.commit("setMyBalance", 0);
        store.commit("setMyNFTReserved", 0);

    }

    const switchNetwork = async (networkidHex: string, networkid: string) => {
        const networkidHexFull = '0x' + networkidHex;
        await connectToNetwork();
        const provider = await web3Modal.connect();

        if (provider.isMetaMask) {
            await provider
                .request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: networkidHexFull }]
                }).then(function () {
                    store.commit("setChainId", parseInt(networkid));
                }).catch((error: any) => {
                    if (error.code == 4902) {
                        provider
                            .request({
                                method: "wallet_addEtherumchain",
                                params: [networkidHexFull]
                            }).then(function () {
                                store.commit("setChainId", parseInt(networkid));
                            }).catch((innererror: any) => {
                                console.log(innererror);
                            });
                    } else {
                        console.log(error);
                    }
                });
        }
        location.reload();
    };

    const fetchAccountData = async () => {
        const accounts = await store.state.web3.eth.getAccounts();
        store.commit("setCurrentUser", accounts[0]);
        store.commit("setChainId", await store.state.web3.eth.getChainId());
    }

    const doConnectNetwork = async () => {
        if (web3Modal.cachedProvider) {
            await connectToNetwork();
        }
    }

    return {
        connectToNetwork,
        disconnectFromNetwork,
        switchNetwork,
        doConnectNetwork
    }
}