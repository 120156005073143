import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.selectedAccount && _ctx.isIMXRegistered)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doConnectNetwork())),
          "aria-label": "Connect",
          class: "dg-button dg-button--full-width dg-nft-page__disconnect-btn"
        }, " Connect Wallet "))
      : _createCommentVNode("", true),
    (_ctx.selectedAccount && _ctx.isIMXRegistered)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.disconnectFromNetwork())),
          "aria-label": "Disconnect",
          class: "dg-button dg-button--full-width dg-nft-page__disconnect-btn"
        }, " Disconnect Wallet "))
      : _createCommentVNode("", true)
  ], 64))
}